<template>
  <button
    :class="['atc-button', { 'is-big': isFullScreen }]"
    @click="$emit('click')"
  >
    <div
      
      :class="[
        'button-with-image',
        { 'is-big': isFullScreen, 'is-inline': !isFullScreen },
      ]"
    >
      <div
        class="product-image"
        :class="{ 'inline-product-image': !isFullScreen }"
        :style="`background-image: url('${product.imageUrl || ''}')`"
      >
        <BagIcon v-if="!product.imageUrl" />
      </div>

      <div v-if="isFullScreen" class="product-information">
        <p class="name">
          {{ product.name || $t("player.atcButton.yourProduct") }}
        </p>
        <p class="price">
          {{ displayCurrency(currency, product.variants[0].price) }}
        </p>
      </div>
    </div>
  </button>
</template>

<script>
import BagIcon from "@/assets/bag-icon.svg";
import { displayCurrency } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    BagIcon,
  },
  data() {
    return {
      displayCurrency,
    };
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("playerSite", ["currency"]),
    ...mapGetters("playerCampaign", ["isFullScreen"]),
    ...mapGetters("player", ["isMobile", "isInPreview"]),

    parentUrl() {
      return this.isInPreview ? "#" : document.referrer + "/cart";
    },
  },
};
</script>

<style lang="scss" scoped>
.atc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.has-shopped.atc-button {
  background: black;
}

.button-with-image {
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  &.is-big {
    font-size: 18px;
    max-width: 280px;
    flex-direction: initial;
    .product-image {
      height: 70px;
      width: 70px;
    }
    svg {
      background: white;
    }
  }
  svg {
    padding: 8px 0;
    border-radius: 5px;
    fill: #585858;
    transform: scale(1.5);
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    transition: background-color 0.3s ease-in-out;
  }
}

.product-information {
  font-family: "Roboto", sans-serif;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: space-evenly;
  align-items: flex-start;
  font-size: 16px;
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 18px;
    max-height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: start;
    width: 180px;
  }
  .price {
    font-weight: 500;
  }
}

.product-image {
  height: 50px;
  width: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0 0 5px 5px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.is-big .product-image {
  border-radius: 5px 0 0 5px;
}

.is-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 3px;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  &:hover {
    background-color: $light-grey;
  }
}
.inline-product-image {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
</style>
